import {createRouter, createWebHashHistory} from 'vue-router'
import {getSession, removeSession} from "@/utils/util";
import {AdminInfo} from "@/api/admin";

const routes = [
  {
    path: '/',
    name: 'Waymon',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/Admin',
    name: 'Admin',
    component: () => import ('../views/Admin.vue'),
    meta: {
      title: '管理员',
    }
  }, {
    path: '/AdminAdd',
    name: 'AdminAdd',
    component: () => import ('../views/AdminAdd.vue'),
    meta: {
      title: '添加管理员'
    }
  }, {
    path: '/AdminEdit',
    name: 'AdminEdit',
    component: () => import ('../views/AdminEdit.vue'),
    meta: {
      title: '管理员编辑'
    }
  }, {
    path: '/passwordEdit',
    name: 'passwordEdit',
    component: () => import ('../views/passwordEdit.vue'),
    meta: {
      title: '修改密码'
    }
  },{
    path: '/File',
    name: 'File',
    component: () => import ('../views/File.vue'),
    meta: {
      title: '文件管理',
    }
  }, {
    path: '/FileCash',
    name: 'FileCash',
    component: () => import ('../views/FileCash.vue'),
    meta: {
      title: '垃圾桶'
    }
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import ('../views/Member.vue'),
    meta: {
      title: '用户列表',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import ('../views/Order.vue'),
    meta: {
      title: '订单管理',
    }
  },
  {
    path: '/OrderAppeal',
    name: 'OrderAppeal',
    component: () => import ('../views/OrderAppeal.vue'),
    meta: {
      title: '申诉列表',
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: () => import ('../views/MemberEdit.vue'),
    meta: {
      title: '用户编辑'
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: () => import ('../views/Role.vue'),
    meta: {
      title: '角色管理',
    }
  },
  {
    path: '/RoleAdd',
    name: 'RoleAdd',
    component: () => import ('../views/RoleAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/RoleEdit',
    name: 'RoleEdit',
    component: () => import ('../views/RoleEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },
  {
    path: '/Help',
    name: 'Help',
    component: () => import ('../views/Help.vue'),
    meta: {
      title: '帮助管理',
    }
  },
  {
    path: '/HelpAdd',
    name: 'HelpAdd',
    component: () => import ('../views/HelpAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/HelpEdit',
    name: 'HelpEdit',
    component: () => import ('../views/HelpEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },
  {
    path: '/System',
    name: 'System',
    component: () => import ('../views/System.vue'),
    meta: {
      title: '系统设置'
    }
  },
  {
    path: '/Report',
    name: 'Report',
    component: () => import ('../views/Report.vue'),
    meta: {
      title: '投诉列表',
    }
  },
  {
    path: '/Static',
    name: 'Static',
    component: () => import ('../views/Static.vue'),
    meta: {
      title: '数据统计',
    }
  },
  {
    path: '/About',
    name: 'About',
    component: () => import ('../views/About.vue'),
    meta: {
      title: '投诉列表',
    }
  },
  {
    path: '/Proxy',
    name: 'Proxy',
    component: () => import ('../views/Proxy.vue'),
    meta: {
      title: '投诉列表',
    }
  },
  {
    path: '/Point',
    name: 'Point',
    component: () => import ('../views/Point.vue'),
    meta: {
      title: '约会地点列表',
    }
  },
  {
    path: '/PointAdd',
    name: 'PointAdd',
    component: () => import ('../views/PointAdd.vue'),
    meta: {
      title: '添加约会地点',
    }
  },
  {
    path: '/PointEdit',
    name: 'PointEdit',
    component: () => import ('../views/PointEdit.vue'),
    meta: {
      title: '约会地点编辑',
    }
  }, {
    path: '/Phrase',
    name: 'Phrase',
    component: () => import ('../views/Phrase.vue'),
    meta: {
      title: '短语管理',
    }
  },{
    path: '/PhraseAdd',
    name: 'PhraseAdd',
    component: () => import ('../views/PhraseAdd.vue'),
    meta: {
      title: '添加短语',
    }
  },{
    path: '/PhraseEdit',
    name: 'PhraseEdit',
    component: () => import ('../views/PhraseEdit.vue'),
    meta: {
      title: '编辑短语',
    }
  },{
    path: '/Meta',
    name: 'Meta',
    component: () => import ('../views/Meta.vue'),
    meta: {
      title: '资料列表',
    }
  },{
    path: '/Authen',
    name: 'Authen',
    component: () => import ('../views/Authen.vue'),
    meta: {
      title: '实名认证',
    }
  },{
    path: '/Education',
    name: 'Education',
    component: () => import ('../views/Education.vue'),
    meta: {
      title: '学历认证',
    }
  },{
    path: '/Imgexamine',
    name: 'Imgexamine',
    component: () => import ('../views/Imgexamine.vue'),
    meta: {
      title: '生活照认证',
    }
  },{
    path: '/Portrait',
    name: 'Portrait',
    component: () => import ('../views/portrait.vue'),
    meta: {
      title: '头像认证',
    }
  },{
    path: '/Operation',
    name: 'Operation',
    component: () => import ('../views/Operation.vue'),
    meta: {
      title: '头像认证',
    }
  },{
    path: '/Avatar',
    name: 'Avatar',
    component: () => import ('../views/Avatar.vue'),
    meta: {
      title: '头像管理',
    }
  },{
    path: '/Account',
    name: 'Account',
    component: () => import ('../views/Account.vue'),
    meta: {
      title: '账户列表',
    }
  },{
    path: '/Amount',
    name: 'Amount',
    component: () => import ('../views/Amount.vue'),
    meta: {
      title: '资金明细',
    }
  },{
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import ('../views/Withdraw.vue'),
    meta: {
      title: '提现列表',
    }
  },{
    path: '/Recharge',
    name: 'Recharge',
    component: () => import ('../views/Recharge.vue'),
    meta: {
      title: '充值列表',
    }
  }, {
    path: '/Hong',
    name: 'Hong',
    component: () => import ('../views/Hong.vue'),
    meta: {
      title: '红娘',
    }
  }, {
    path: '/StaticMember',
    name: 'StaticMember',
    component: () => import ('../views/StaticMember.vue'),
    meta: {
      title: '用户数据',
    }
  }, {
    path: '/StaticOrder',
    name: 'StaticOrder',
    component: () => import ('../views/StaticOrder.vue'),
    meta: {
      title: '订单数据',
    }
  }, {
    path: '/Promote',
    name: 'Promote',
    component: () => import ('../views/Promote.vue'),
    meta: {
      title: '推广位',
    }
  }, {
    path: '/PromoteAdd',
    name: 'PromoteAdd',
    component: () => import ('../views/PromoteAdd.vue'),
    meta: {
      title: '添加推广位',
    }
  }, {
    path: '/PromoteEdit',
    name: 'PromoteEdit',
    component: () => import ('../views/PromoteEdit.vue'),
    meta: {
      title: '编辑推广位',
    }
  }, {
    path: '/PromoteStatic',
    name: 'PromoteStatic',
    component: () => import ('../views/PromoteStatic.vue'),
    meta: {
      title: '推广报表',
    }
  }, {
    path: '/Destroy',
    name: 'Destroy',
    component: () => import ('../views/Destroy.vue'),
    meta: {
      title: '注销列表',
    }
  }, {
    path: '/Logger',
    name: 'Logger',
    component: () => import ('../views/Logger.vue'),
    meta: {
      title: '更新日志',
    }
  }, {
    path: '/LoggerAdd',
    name: 'LoggerAdd',
    component: () => import ('../views/LoggerAdd.vue'),
    meta: {
      title: '添加日志',
    }
  }, {
    path: '/Path',
    name: 'Path',
    component: () => import ('../views/Path.vue'),
    meta: {
      title: '用户路径',
    }
  }, {
    path: '/Address',
    name: 'Address',
    component: () => import ('../views/Address.vue'),
    meta: {
      title: '我的站点',
    }
  }, {
    path: '/Category',
    name: 'Category',
    component: () => import ('../views/Category.vue'),
    meta: {
      title: '标签类型',
    }
  }, {
    path: '/CategoryAdd',
    name: 'CategoryAdd',
    component: () => import ('../views/CategoryAdd.vue'),
    meta: {
      title: '标签类型添加',
    }
  }, {
    path: '/CategoryEdit',
    name: 'CategoryEdit',
    component: () => import ('../views/CategoryEdit.vue'),
    meta: {
      title: '标签类型修改',
    }
  }, {
    path: '/Tag',
    name: 'Tag',
    component: () => import ('../views/Tag.vue'),
    meta: {
      title: '标签列表',
    }
  }, {
    path: '/TagAdd',
    name: 'TagAdd',
    component: () => import ('../views/TagAdd.vue'),
    meta: {
      title: '标签添加',
    }
  }, {
    path: '/TagEdit',
    name: 'TagEdit',
    component: () => import ('../views/TagEdit.vue'),
    meta: {
      title: '标签修改',
    }
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = getSession('token')
  if (token) { //已经登录
    document.title = to.meta.title
    AdminInfo({

    }).then((res)=>{
      if (res.status == 0) {
        next()
      } else if (res.status == -1 ||res.status == 401 ) {
        removeSession('token')
        removeSession('admin')
        if (to.name !== "Login") {
          next('/Login')
        }
      }
    })
  } else { // 什么都没有 去授权
    if (to.name !== "Login") {
      next('/Login')
    } else {
      next()
    }
  }
})

export default router
